import { useContext, useEffect, useState } from "react";
import { GlobalDataContext } from "../../context/context";
import axios from "axios";
import { FaStar } from "react-icons/fa";


export default function CustomReviews() {

    const { rpdata } = useContext(GlobalDataContext);

    const [reviewsDB, setReviewsDB] = useState([]);
    const idCompany = rpdata?.docId;

    useEffect(() => {
        const getReviews = async () => {
            const url = `https://apireviews.herokuapp.com/api/reviews/${idCompany}`;
            const resp = await axios.get(url);
            setReviewsDB(resp.data);
            // console.log(resp.data);
            //order by date
            const reviews = resp.data.sort((a, b) => {
                return new Date(b.Date) - new Date(a.Date);
            });
            setReviewsDB(reviews);
        };
        getReviews();
    }, [idCompany]);
    const totalReviews = reviewsDB.map(item => (item.ReviewsStars >= 3 ? item.ReviewsStars : 0)).reduce((prev, curr) => prev + curr, 0);
    const porcReviews = (totalReviews / reviewsDB.length).toFixed(2)

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="text-[15px] leading-relaxed">
                {/* {
                    rpdata?.works?.map((item, index) => {
                        return (
                            <div key={index} className="flex items-center justify-start">
                                <p className="text-[15px] font-semibold">{item.title}</p>
                                <p className="text-[15px] text-gray-400"> {item.description}</p>
                            </div>
                        );
                    })
                } */}
                {isReadMore ? text.substring(0, 60) : text}
                <span onClick={toggleReadMore} className="read-or-hide text-gray-400">
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
        );
    };

    const moverReview = [
        {
            UserName: "Florentino Roque",
            Rate: 5.0,
            Date: "1 month ago",
            Description: "A great experience from consultation to completion. Although our project was just a small paver patio, the service was no small feat! Marcos provided some expert design suggestions and the team delivered on all their promises. Our home is in a condo community, so we were particularly pleased with the team's professionalism, speed, and attention to keeping the workplace tidy. The finished project is beautiful! 100% recommended! Thank you!"
        },
        {
            UserName: "Carmina Herrera",
            Rate: 5.0,
            Date: "2 months ago",
            Description: "We are delighted with the work of the entire Hidalgo Landscape team. Our backyard is a brand new, beautiful and usable space. The team was nothing but professional and the correspondence was extremely timely and courteous. We look forward to spending many hours in our new backyard for many seasons to come! I recommend 100% Hidalgo Landscape."
        },
        {
            UserName: "Erick Alejandro Hernandez Samayoa",
            Rate: 5.0,
            Date: "2 months ago",
            Description: "Hidalgo Landscape & Services LLC services truly stand out. The company is known for providing excellent customer service, showing remarkable attention to detail, and offering top-quality work. Hidalgo Landscape dedication to excellence makes them a trusted choice for anyone looking for professional and aesthetically pleasing landscaping services. We highly recommend them for your landscaping needs."
        },
        {
            UserName: "Julisa Rodriguez",
            Rate: 5.0,
            Date: "2 months ago",
            Description: "I am happy and usually had a new garden installation that included lawn, soil work, grading, full automated irrigation system, planting trees, shrubs and rock work. Amazing, friendly and fast response from the crew. Highly recommended."
        }
    ]



    return (
        <section className="w-full flex flex-col items-center justify-center py-20 h-auto ">
            <div className="w-full flex flex-col bg-[#f3f3f3] h-auto rounded-xl">
                <div className="px-5 justify-start flex py-5 text-center flex-wrap">
                    <div className="flex items-center border-gray-500 border-b-[4px]">
                        <p className="px-2">All Reviews</p>
                        <p className="font-semibold"> 5.0 </p>
                    </div>

                    <div className="flex px-5 items-center">
                        <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Directorios%2Fgoogle%20min.png?alt=media&token=40cf9139-1153-40c7-85cb-a011d3b848d9" alt="google svg" className="w-[25px] h-[25px]" />
                        <p className="px-2">Google</p>
                        <p className="font-semibold"> 5.0 </p>
                    </div>
                    {/* 
                    <div className="flex items-center">
                        <img src="https://acortar.link/cgmMNf" alt="google svg" className="w-[20px] h-[20px]" />
                        <p className="px-2">Yelp</p>
                        <p className="font-semibold"> 5.0 </p>
                    </div> */}
                </div>

                <div className="flex w-full h-auto px-5 border-t justify-between">
                    <div className="flex flex-col">
                        <div><p className="text-[20px]">Overall Rating</p></div>
                        <div className="flex">
                            <div><p className="font-bold text-[18px]">5.0</p></div>
                            <div className={`flex ${porcReviews >= 1 ? 'flex-row' : 'flex-col'} p-2 justify-start items-center gap-2`}>
                                <div className="flex">
                                    <FaStar className="text-[#ff4c4c] text-[20px]" />
                                    <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                    <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                    <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                    <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-7">
                        <a href={rpdata?.gmb?.link} className="p-3 bg1 rounded-md text-white font-bold" target="_blank" rel='noopener noreferrer'>
                            Write a Review
                        </a>
                    </div>
                </div>
            </div>

            <div className="w-full h-auto my-10 mx-auto gap-5 grid md:grid-cols-5 grid-cols-1">
                {
                    moverReview.map((item, index) => {
                        return (
                            <article key={index} className="h-auto">

                                <div className="p-5 flex flex-col rounded-[28px] items-start mb-2 bg-[#f3f3f3] w-auto relative">
                                    <div className={`flex ${porcReviews >= 1 ? 'flex-row' : 'flex-col'} p-2 justify-start items-center gap-2 `}>
                                        <div className="flex">
                                            <FaStar className="text-[#ff4c4c] text-[20px]" />
                                            <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                            <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                            <FaStar ar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                            <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                        </div>
                                    </div>
                                    <ReadMore>{item?.Description}</ReadMore>

                                </div>
                                <div className="bg-[#f3f3f3] w-[28px] h-[18px] relative overflow-hidden rounded-br-[30px] -mt-2 ml-7">
                                    <div className="w-[45px] h-[100px] bg-white rounded-full absolute -left-8 -bottom-1">
                                    </div>
                                </div>

                                <div>
                                    <div className="flex flex-col">
                                        <div className="py-4 flex space-x-2">
                                            <div className="flex justify-center">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/user.jfif?alt=media&token=2e7c59b5-a71c-4739-8260-bb88b37b08fa"
                                                    alt="perfil-foto"
                                                    className="w-[40px] h-[40px] rounded-full object-cover" />
                                            </div>

                                            <div>
                                                <div>
                                                    <span className="text-[15px] font-semibold"> {item?.UserName} </span>
                                                </div>
                                                <div className="flex items-end text-[11px] text-gray-400">
                                                    <span>{item?.Date}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        );
                    })
                }
            </div>
        </section>
    );
}
